import Choices from 'choices.js'

document.addEventListener  'turbolinks:load', () ->
  el= document.getElementById('keyword')
  return false if not el?

  new Choices '#keyword', {
    removeItemButton: true
    delimiter: ','
    editItems: true
    addItemText: (value) -> "Presione Enter para añadir <b>#{value}</b>"
  }